<template>
  <div class="text-black bg-warning py-3" style="border: 4px dashed black">
    <div class="error-title text-bold">
      {{ "pageblock" }}
      <div outline color="black">
        {{ componentName }}
      </div>
      {{ "notFound" }}
    </div>

    <div class="error-title text-bold">
      {{ "path" }}:
      <div outline color="black">
        {{ path }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    componentName: {
      type: String,
      required: true,
    },
    path: {
      type: String,
    },
  },
}
</script>
