<template>
  <div>
    <page-builder
      v-if="xJSON"
      :key="contentMetaData.id"
      :render-blocks="renderBlocks"
      class="flex column"
    />
    <b-row
      deck
      v-if="loading"
      class="text-center justify-content-center p-5 adapt"
    >
      <b-spinner />
    </b-row>
    <div v-else-if="!renderBlocks.length" class="h5 text-center adapt mt-5">
      <b-icon
        font-scale="2.5"
        icon="exclamation-triangle"
        class="border border-warning rounded p-2"
        variant="warning"
      />
      <div class="m-4">
        Oops, looks like this product does not have video content.<br />
        To access the media files for this product, please visit the streaming
        site on your computer or mobile device
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import PageBuilder from "./PageBuilder"
export default {
  props: ["contentID"],
  name: "Player",
  components: { PageBuilder },
  data() {
    return {
      renderBlocks: [],
      loading: false,
    }
  },
  computed: {
    ...mapState(["contentMetaData", "eJSON"]),
    xJSON() {
      return this.contentMetaData.xJSON
    },
  },
  async created() {
    this.loading = true
    this.$store.commit("saveContentID", this.contentID)

    let response = await this.$store.dispatch("fetchContentMetadata", {
      contentIDs: [this.contentID],
    })

    if (response.success) {
      await this.$store.commit("saveContentMetaData", response.data[0])
      this.$apiPublic("remember/it", {
        logType: "launchContent",
        description: `user launched content '${this.contentMetaData.contentName}'`,
        context: "library",
        contentID: this.contentID,
        contentProjectID: this.contentMetaData.projectID,
      })
    }

    const pageData = this.xJSON?.pages?.filter((page) => page.meta.name === "video") || {}
    let pageBlocks = pageData[0]?.pageBlocks || []

    this.renderBlocks = pageBlocks
      .filter((pageBlock) => !pageBlock.hidden)
      .map((pageBlock) => {
        const blockFromLib = this.xJSON.blockLibrary[pageBlock.id] || {}
        const retVal = {
          id: pageBlock.id,
          props: blockFromLib.props,
          component: () => import(`../../../components/${blockFromLib.src}`),
        }
        return retVal
      })
    this.loading = false
  },
}
</script>
<style>
.adapt {
  min-height: calc(100vh - 62px) !important;
  overflow-y: hidden !important;
}
</style>
