<template>
  <page-block-not-found
    v-if="notFound"
    :path="renderBlock.path"
    :component-name="renderBlock.id"
  />
  <component
    :is="component"
    v-else
    v-bind="renderBlock.props"
    :uid="renderBlock.id"
  />
</template>

<script>
import PageBlockNotFound from "./PageBlockNotFound"

export default {
  name: "PageBlock",
  components: {
    PageBlockNotFound,
  },
  props: {
    renderBlock: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      notFound: false,
      component: null,
    }
  },

  watch: {
    "renderBlock.component": {
      immediate: true,
      async handler() {
        try {
          this.component = (await this.renderBlock.component()).default
        } catch (error) {
          console.error(error)
          this.notFound = true
        }
      },
    },
  },
}
</script>
